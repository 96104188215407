var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("q-form", { ref: "editForm" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
            },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "내부정보" },
                },
                [
                  _c(
                    "template",
                    { slot: "card-button" },
                    [
                      _c(
                        "q-btn-group",
                        { attrs: { outline: "" } },
                        [
                          _vm.editable && _vm.isOld
                            ? _c("c-btn", {
                                attrs: {
                                  label: "경고표지(6 x 4)",
                                  icon: "print",
                                },
                                on: {
                                  btnClicked: function ($event) {
                                    return _vm.printWarning("s")
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.editable && _vm.isOld
                            ? _c("c-btn", {
                                attrs: {
                                  label: "경고표지(11 x 9)",
                                  icon: "print",
                                },
                                on: {
                                  btnClicked: function ($event) {
                                    return _vm.printWarning("m")
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm.editable
                            ? _c("c-btn", {
                                attrs: {
                                  url: _vm.saveUrl,
                                  isSubmit: _vm.isSave,
                                  param: _vm.chemData,
                                  mappingType: _vm.mappingType,
                                  label: "LBLSAVE",
                                  icon: "save",
                                },
                                on: {
                                  beforeAction: _vm.saveInfo,
                                  btnCallback: _vm.saveCallback,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-material", {
                          attrs: {
                            required: true,
                            editable: _vm.editable && _vm.isUpdate,
                            label: "자재코드",
                            name: "materialCd",
                            type: "codename",
                            data: _vm.chemData,
                            plantCd: _vm.chemData.plantCd,
                          },
                          on: {
                            "update:data": function ($event) {
                              _vm.chemData = $event
                            },
                          },
                          model: {
                            value: _vm.chemData.materialCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "materialCd", $$v)
                            },
                            expression: "chemData.materialCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-text", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            label: "품명",
                            name: "mdmChemMaterialName",
                          },
                          model: {
                            value: _vm.chemData.mdmChemMaterialName,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "mdmChemMaterialName", $$v)
                            },
                            expression: "chemData.mdmChemMaterialName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-plant", {
                          attrs: {
                            required: true,
                            editable: _vm.editable,
                            type: "edit",
                            name: "plantCd",
                          },
                          model: {
                            value: _vm.chemData.plantCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "plantCd", $$v)
                            },
                            expression: "chemData.plantCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-3" },
                      [
                        _c("c-dept", {
                          attrs: {
                            editable: _vm.editable,
                            label: "사용부서",
                            name: "depts",
                          },
                          model: {
                            value: _vm.chemData.depts,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "depts", $$v)
                            },
                            expression: "chemData.depts",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "", noHeaderCard: true },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-vendor", {
                          attrs: {
                            editable: _vm.editable,
                            selfFlag: false,
                            label: "협력사",
                            name: "deliveryVendorCd",
                          },
                          model: {
                            value: _vm.chemData.deliveryVendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "deliveryVendorCd", $$v)
                            },
                            expression: "chemData.deliveryVendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-vendor", {
                          attrs: {
                            editable: _vm.editable,
                            selfFlag: false,
                            label: "제조사",
                            name: "mfgVendorCd",
                          },
                          model: {
                            value: _vm.chemData.mfgVendorCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "mfgVendorCd", $$v)
                            },
                            expression: "chemData.mfgVendorCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "비중",
                            name: "specificGravity",
                          },
                          model: {
                            value: _vm.chemData.specificGravity,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "specificGravity", $$v)
                            },
                            expression: "chemData.specificGravity",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            codeGroupCd: "USAGE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "usageCd",
                            label: "용도",
                          },
                          model: {
                            value: _vm.chemData.usageCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "usageCd", $$v)
                            },
                            expression: "chemData.usageCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable:
                              _vm.editable &&
                              _vm.chemData.usageCd == "UC99999999",
                            label: "용도(기타)",
                            name: "usageEtc",
                          },
                          model: {
                            value: _vm.chemData.usageEtc,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "usageEtc", $$v)
                            },
                            expression: "chemData.usageEtc",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            codeGroupCd: "PROPERTIES_STATE_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "propertiesStateCd",
                            label: "성상",
                          },
                          model: {
                            value: _vm.chemData.propertiesStateCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "propertiesStateCd", $$v)
                            },
                            expression: "chemData.propertiesStateCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            codeGroupCd: "CHM_IN_UNIT_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "inUnitCd",
                            label: "입고단위",
                          },
                          model: {
                            value: _vm.chemData.inUnitCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "inUnitCd", $$v)
                            },
                            expression: "chemData.inUnitCd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            type: "number",
                            label: "입고 환산계수",
                            name: "inConversonFactor",
                          },
                          model: {
                            value: _vm.chemData.inConversonFactor,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "inConversonFactor", $$v)
                            },
                            expression: "chemData.inConversonFactor",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-select", {
                          attrs: {
                            codeGroupCd: "CHM_IN_CONVERSION_UNIT_CD",
                            type: "edit",
                            itemText: "codeName",
                            itemValue: "code",
                            name: "inConversonUnitCd",
                            label: "입고 환산단위",
                          },
                          model: {
                            value: _vm.chemData.inConversonUnitCd,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "inConversonUnitCd", $$v)
                            },
                            expression: "chemData.inConversonUnitCd",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "", noHeaderCard: true },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "어는점",
                            name: "freezingPoint",
                          },
                          model: {
                            value: _vm.chemData.freezingPoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "freezingPoint", $$v)
                            },
                            expression: "chemData.freezingPoint",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "끓는점",
                            name: "boilPoint",
                          },
                          model: {
                            value: _vm.chemData.boilPoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "boilPoint", $$v)
                            },
                            expression: "chemData.boilPoint",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "인화점",
                            name: "flashPoint",
                          },
                          model: {
                            value: _vm.chemData.flashPoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "flashPoint", $$v)
                            },
                            expression: "chemData.flashPoint",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "발화점",
                            name: "firePoint",
                          },
                          model: {
                            value: _vm.chemData.firePoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "firePoint", $$v)
                            },
                            expression: "chemData.firePoint",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "인허가대상 물질 여부",
                            name: "licensingFlag",
                          },
                          model: {
                            value: _vm.chemData.licensingFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "licensingFlag", $$v)
                            },
                            expression: "chemData.licensingFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "위험물 여부",
                            name: "dangerFlag",
                          },
                          model: {
                            value: _vm.chemData.dangerFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "dangerFlag", $$v)
                            },
                            expression: "chemData.dangerFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "유해화학물질(PSM) 여부",
                            name: "psmFlag",
                          },
                          model: {
                            value: _vm.chemData.psmFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "psmFlag", $$v)
                            },
                            expression: "chemData.psmFlag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm cardClass-height-100",
                  attrs: { title: "", noHeaderCard: true },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("font", { staticClass: "formLabelTitle txtlabel" }, [
                          _vm._v("제품 분류 "),
                        ]),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "제조",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.makeFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "makeFlag", $$v)
                            },
                            expression: "chemData.makeFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "수입",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.impFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "impFlag", $$v)
                            },
                            expression: "chemData.impFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "구매",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.buyFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "buyFlag", $$v)
                            },
                            expression: "chemData.buyFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "사용",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.usingFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "usingFlag", $$v)
                            },
                            expression: "chemData.usingFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "수출",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.expFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "expFlag", $$v)
                            },
                            expression: "chemData.expFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "판매",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.salesFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "salesFlag", $$v)
                            },
                            expression: "chemData.salesFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c("font", { staticClass: "formLabelTitle txtlabel" }, [
                          _vm._v("유해화학물질 포함 여부 "),
                        ]),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "유독물질",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.toxicPoisonFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "toxicPoisonFlag", $$v)
                            },
                            expression: "chemData.toxicPoisonFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "허가물질",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.toxicPermitFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "toxicPermitFlag", $$v)
                            },
                            expression: "chemData.toxicPermitFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "제한물질",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.toxicLimitFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "toxicLimitFlag", $$v)
                            },
                            expression: "chemData.toxicLimitFlag",
                          },
                        }),
                        _c("q-checkbox", {
                          staticClass: "customqcbox",
                          attrs: {
                            editable: _vm.editable,
                            dense: "",
                            color: "orange-custom",
                            label: "금지물질",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          model: {
                            value: _vm.chemData.toxicProhibitFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "toxicProhibitFlag", $$v)
                            },
                            expression: "chemData.toxicProhibitFlag",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" },
            [
              _c(
                "c-card",
                {
                  staticClass: "cardClassDetailForm",
                  attrs: { title: "", noHeaderCard: true },
                },
                [
                  _c("template", { slot: "card-detail" }, [
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-chem-material", {
                          attrs: {
                            editable: _vm.editable,
                            type: "edit",
                            label: "이전화학자재 품명",
                            name: "preMdmChemMaterialId",
                          },
                          model: {
                            value: _vm.chemData.preMdmChemMaterialId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.chemData,
                                "preMdmChemMaterialId",
                                $$v
                              )
                            },
                            expression: "chemData.preMdmChemMaterialId",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-4" },
                      [
                        _c("c-checkbox", {
                          attrs: {
                            editable: _vm.editable,
                            isFlag: true,
                            label: "LBLUSEFLAG",
                            name: "useFlag",
                          },
                          model: {
                            value: _vm.chemData.useFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "useFlag", $$v)
                            },
                            expression: "chemData.useFlag",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.chemData.useFlag == "N",
                            expression: "chemData.useFlag == 'N'",
                          },
                        ],
                        staticClass: "col-4",
                      },
                      [
                        _c("c-text", {
                          attrs: {
                            editable: _vm.editable,
                            label: "미사용 사유",
                            name: "unusedReason",
                          },
                          model: {
                            value: _vm.chemData.unusedReason,
                            callback: function ($$v) {
                              _vm.$set(_vm.chemData, "unusedReason", $$v)
                            },
                            expression: "chemData.unusedReason",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }